import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngMetaverseLunchpad = loadable(() => import("../components/MetaverseLunchpad/english.js"));
const ArabicMetaverseLunchpad = loadable(() => import("../components/MetaverseLunchpad/arabic.js"));
const ChineseMetaverseLunchpad = loadable(() => import("../components/MetaverseLunchpad/chinese.js"));

const schema = {
  "@context": "http://schema.org/",
  "@graph": [
    {
      "@type": "product",
      "image": "https://softtik.com/images/nft-collection/invest-section-15.webp",
      "name": "Top NFT Metaverse Launchpad Development Company",
      "description": "Softtik is leading NFT metaverse launchpad development company with highly skilled developers to deliver high-end solutions using cutting-edge technologies.",
      "brand": "Softtik Technologies",
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.8",
        "reviewCount": "114"
      }
    },
    {
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "What is metaverse launchpad?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>A <b>metaverse launchpad</b> can be seen as a virtual dais for staging multiple metaverse projects in their early stages. New metaverse project owners contact the launchpads to list their projects for public outreach.</p>"
        }
      }, {
        "@type": "Question",
        "name": "how is Metaverse developed?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Metaverse application development requires a 3D gaming engine such as Unity or Unreal, a programming language, and an app/game development platform.</p>"
        }
      }, {
        "@type": "Question",
        "name": "How much does it cost to develop a Metaverse?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>There is no exact answer to this question because the cost of developing a metaverse depends on your requirements.</p>"
        }
      }
      ]
    }
  ]
}

export class MetaverseLunchpad extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lang: "English",
    }
  };

  async componentDidMount() {
    let region = localStorage.getItem('region');

    if (region == 'ae') this.setState({ lang: "Arabic" });
    // else if (region == 'sa') this.setState({ lang: "Arabic" });
    else if (region == 'cn') this.setState({ lang: "China" });
    else this.setState({ lang: "English" });
  };

  render() {
    let { lang } = this.state;

    return (
      <>
        <InnerLayout header='Main' fallback={<Loader />}>
          <Head
            type="Website"
            fallback={<Loader />}
            schemaMarkup={schema}
            path="/metaverse-launchpad-development-services/"
            title="Metaverse Launchpad Development Company - Softtik"
            thumbnail="https://softtik.com/images/nft-collection/invest-section-15.webp"
            description="Softtik is leading NFT metaverse launchpad development company with highly skilled developers to deliver high-end solutions using cutting-edge technologies."
          />
          <main>
            <div id="wrapper">
              <div className={`collection-page ${lang == "Arabic" && 'rtl'}`}>
                {lang == "Arabic"
                  ? <ArabicMetaverseLunchpad />
                  : lang == "China"
                    ? <ChineseMetaverseLunchpad />
                    : <EngMetaverseLunchpad />
                }
              </div>
            </div>
          </main>
        </InnerLayout >
      </>
    )
  };
};

export default MetaverseLunchpad;